<template>
    <div class="content">
        <main>
            <directus-article from="id" getter="5" :showHeading="false" />
        </main>
    </div>
</template>

<script>
import DirectusArticle from '@/components/ext/DirectusArticle.vue';

/**
 * About view
 * Display the About page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'About',
    components: {
        DirectusArticle
    },
    metaInfo: function() {
        return {
            title: `${this.$t("message.privacy.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.privacy.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.privacy.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.privacy.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

@media(min-width: $desktop-landscape) {
    .about {
        width: 50%;
        margin: auto;
    }
}
</style>
